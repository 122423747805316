
import { Vue, Options, prop } from "vue-class-component";
import { LocationController } from "@/app/ui/controllers/LocationController";
import { CargoController } from "@/app/ui/controllers/CargoController";
import { cargoTypeData } from "@/app/ui/views/cargo/modules/cargo-module";
import formatDateWithoutTime from "@/app/infrastructures/misc/common-library/FormatDateWithoutTime";
import formatTimeNumber from "@/app/infrastructures/misc/common-library/FormatTimeNumber";
import dateDiffHoursMinute from "@/app/infrastructures/misc/common-library/DateDiffHoursMinute";
import CardDetailFlight from "@/app/ui/views/cargo/booking-cargo/components/card-detail-flight.vue";
import { firstCapitalize } from "@/app/infrastructures/misc/Utils";
import { ReadyToCargoController } from "@/app/ui/controllers/ReadyToCargoController";

class Props {
  showChangeCargo = prop<boolean>({
    default: false,
    type: Boolean
  });
}

@Options({
  emits: ["changeCargo"],
  components: { CardDetailFlight }
})
export default class CargoTypeSection extends Vue.with(Props) {
  changeCargo() {
    this.$emit("changeCargo");
    this.toggleFlightsDetail = false;
  }
  formatDate(date: any) {
    date = new Date(date);
    return formatDateWithoutTime(date);
  }

  formatTimeNumber(date: any) {
    date = new Date(date);
    return formatTimeNumber(date).substring(0, 5);
  }

  dateDiffHoursMinute(startDate: any, endDate: any) {
    return dateDiffHoursMinute(startDate, endDate);
  }

  get originCity() {
    return `${LocationController.districtDetail.cityCode} - ${firstCapitalize(
      LocationController.districtDetail.cityName.toLowerCase()
    )}`;
  }

  get destinationCity() {
    return `${this.formBooking.destinationCityCode} - ${firstCapitalize(
      this.formBooking.destinationCityName.toLowerCase()
    )}`;
  }

  get formBooking() {
    return CargoController.form;
  }

  get cargoType() {
    return cargoTypeData(this.formBooking.cargoType);
  }

  get isPlaneAuto() {
    return (
      this.formBooking.cargoType === "plane" &&
      this.formBooking.cargoTypeStatus === "auto"
    );
  }

  toggleFlightsDetail = false;

  onToggleFlightsDetail() {
    this.toggleFlightsDetail = !this.toggleFlightsDetail;
  }

  get isChangeCargoType() {
    return CargoController.isChangeCargoType;
  }

  get flowBooking() {
    return ReadyToCargoController.flowBooking;
  }

  get cargoInputForm() {
    return CargoController.cargoInputForm;
  }
  
  get isFromRebookingRTC() {
    return CargoController.isRebookingFromRTC;
  }
}
