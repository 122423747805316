import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "flex gap-x-6 items-end w-11/12" }
const _hoisted_2 = {
  key: 0,
  class: "text-red-lp-100 text-12px"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LpInput = _resolveComponent("LpInput")!
  const _component_DataWrapper = _resolveComponent("DataWrapper")!
  const _component_LpButton = _resolveComponent("LpButton")!

  return (_openBlock(), _createBlock("div", null, [
    _createVNode("div", _hoisted_1, [
      _createVNode(_component_DataWrapper, {
        class: "w-8/12",
        label: _ctx.$t('No. Kargo')
      }, {
        default: _withCtx(() => [
          _createVNode(_component_LpInput, {
            modelValue: _ctx.formBooking.cargoNumber,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.formBooking.cargoNumber = $event)),
            placeholder: _ctx.$t('Masukan No. kargo'),
            onChange: _cache[2] || (_cache[2] = ($event: any) => (_ctx.updateFormBookingCargoNumber($event)))
          }, null, 8, ["modelValue", "placeholder"])
        ]),
        _: 1
      }, 8, ["label"]),
      _createVNode(_component_LpButton, {
        onClick: _ctx.onCheckAwb,
        customClass: "px-8 my-1.5 py-2.5",
        title: _ctx.$t('Periksa'),
        textColor: "white",
        disabled: _ctx.disabledCheckAwb
      }, null, 8, ["onClick", "title", "disabled"])
    ]),
    (_ctx.errorAwb)
      ? (_openBlock(), _createBlock("div", _hoisted_2, " *" + _toDisplayString(_ctx.$t('Pastikan nomor cargo telah sesuai, kesalahan pada nomor cargo akan menjadi tanggung jawab konsolidator terkait')), 1))
      : _createCommentVNode("", true)
  ]))
}