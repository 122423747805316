import { toDisplayString as _toDisplayString, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "relative mt-4"
}
const _hoisted_2 = { class: `flex gap-x-16` }
const _hoisted_3 = { class: "space-y-2" }
const _hoisted_4 = { class: "font-semibold text-black-lp-300" }
const _hoisted_5 = { class: "font-semibold text-20px text-red-lp-300" }
const _hoisted_6 = { class: "space-y-2" }
const _hoisted_7 = { class: "font-semibold text-black-lp-300" }
const _hoisted_8 = { class: "font-semibold text-20px text-red-lp-300" }
const _hoisted_9 = { class: "space-y-2" }
const _hoisted_10 = { class: "font-semibold text-black-lp-300" }
const _hoisted_11 = { class: "font-semibold text-20px text-red-lp-300" }
const _hoisted_12 = { class: "space-y-2" }
const _hoisted_13 = { class: "font-semibold text-black-lp-300" }
const _hoisted_14 = { class: "font-semibold text-20px text-red-lp-300" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TableV2 = _resolveComponent("TableV2")!
  const _component_Modal = _resolveComponent("Modal")!

  return (_openBlock(), _createBlock(_Fragment, null, [
    (_ctx.sttResult.length)
      ? (_openBlock(), _createBlock("div", _hoisted_1, [
          _createVNode(_component_TableV2, {
            data: _ctx.sttResult,
            columns: _ctx.columns,
            borderTop: false,
            pagination: _ctx.pagination,
            "is-hard-pagination": true,
            "is-quick-search": true,
            "is-sorting": true,
            "pagination-style": "v2",
            customButtonAction: _ctx.customButton,
            autoHeight: "",
            isPinnedScrollBottom: false
          }, {
            "top-left-table": _withCtx(() => [
              _createVNode("div", _hoisted_2, [
                _createVNode("div", _hoisted_3, [
                  _createVNode("div", _hoisted_4, _toDisplayString(_ctx.$t('Total STT')), 1),
                  _createVNode("div", _hoisted_5, _toDisplayString(_ctx.totalStt), 1)
                ]),
                _createVNode("div", _hoisted_6, [
                  _createVNode("div", _hoisted_7, _toDisplayString(_ctx.$t('Total Koli')), 1),
                  _createVNode("div", _hoisted_8, _toDisplayString(_ctx.totalSttPieces), 1)
                ]),
                _createVNode("div", _hoisted_9, [
                  _createVNode("div", _hoisted_10, _toDisplayString(_ctx.$t('Total Berat Kotor Kargo')), 1),
                  _createVNode("div", _hoisted_11, _toDisplayString(_ctx.convertDecimal(_ctx.totalGrossWeights)) + " Kg ", 1)
                ]),
                _createVNode("div", _hoisted_12, [
                  _createVNode("div", _hoisted_13, _toDisplayString(_ctx.$t('Total Berat Volume Kargo')), 1),
                  _createVNode("div", _hoisted_14, _toDisplayString(_ctx.convertDecimal(_ctx.totalVolumeWeights)) + " Kg ", 1)
                ])
              ])
            ]),
            _: 1
          }, 8, ["data", "columns", "pagination", "customButtonAction"])
        ]))
      : _createCommentVNode("", true),
    (_ctx.isShowPopup)
      ? (_openBlock(), _createBlock(_component_Modal, {
          key: 1,
          onSubmit: _ctx.popupModalData?.success?.action,
          onClose: _ctx.popupModalData?.cancel?.action,
          title: _ctx.popupModalData.title,
          message: _ctx.popupModalData.message,
          image: _ctx.popupModalData.image,
          textSuccess: _ctx.popupModalData?.success?.text,
          textCancel: _ctx.popupModalData?.cancel?.text,
          class: "px-8"
        }, null, 8, ["onSubmit", "onClose", "title", "message", "image", "textSuccess", "textCancel"]))
      : _createCommentVNode("", true)
  ], 64))
}