
import numberOnly from "@/app/infrastructures/misc/common-library/NumberOnly";
import { CargoController } from "@/app/ui/controllers/CargoController";
import { MainAppController } from "@/app/ui/controllers/MainAppController";
import { DimensionPieces } from "@/data/payload/api/CargoApiRequest";
import { ModalMessageEntities } from "@/domain/entities/MainApp";
import { prop, Vue } from "vue-class-component";
class Props {
  formDetailPieces = prop<{ totalPieces: number; dimensionPieces: DimensionPieces[] }>({
    default: {
      totalPieces: 0,
      dimensionPieces: [
        {
          pieces: 0,
          length: 30,
          width: 20,
          height: 10,
          grossWeight: 1,
        },
      ],
    },
    type: Object,
  });
}
export default class PopupDetailKoli extends Vue.with(Props) {
  mounted() {
    if (!this.isHaveBeenSubmitDetailPieces) {
      this.formDetailPieces.dimensionPieces[0].pieces = this.formActualPieces;
    }
  }
  get showPopupDimensionPieces() {
    return CargoController.showPopupDimensionPieces;
  }

  disabledPieces = true;
  errorIndex = -1;
  errorLessThanTotalPieces = false;
  isErrorInputNol = {
    totalPieces: false,
    dimension: false,
    volumeWeight: false,
    pieces: false,
  };
  errorInputNol = false;

  get isHaveBeenSubmitDetailPieces() {
    return CargoController.isHaveBeenSubmitDetailPieces;
  }

  get totalPiecesDisplay() {
    return `${this.formDetailPieces.totalPieces} Koli`;
  }

  setDisabledPieces() {
    this.disabledPieces = !this.disabledPieces;
  }

  setErrorIndex(index: number) {
    this.errorIndex = index;
    this.errorLessThanTotalPieces = false;
    this.onResetAction();
  }

  get countPieces() {
    return this.formDetailPieces.dimensionPieces
      .map((key: any) => +key.pieces)
      .reduce((a, b) => a + b);
  }

  get errorMaxThanTotalPieces() {
    return (
      this.formDetailPieces.totalPieces < this.countPieces || isNaN(this.countPieces)
    );
  }

  numberOnly(value: string) {
    return numberOnly(value);
  }

  addPieces() {
    this.formDetailPieces.dimensionPieces.push({
      pieces: 0,
      length: 30,
      width: 20,
      height: 10,
      grossWeight: 1,
    });
    this.onResetAction();
  }

  onResetAction() {
    this.errorLessThanTotalPieces = false;
    this.errorInputNol = false;
    this.isErrorInputNol.dimension = false;
    this.isErrorInputNol.pieces = false;
    this.isErrorInputNol.totalPieces = false;
    this.isErrorInputNol.volumeWeight = false;
    CargoController.setHaveBeenSubmit(false);
  }

  removePieces(index: number) {
    if (index > -1) {
      this.formDetailPieces.dimensionPieces.splice(index, 1);
    }
    if (this.errorMaxThanTotalPieces) {
      this.errorIndex = this.formDetailPieces.dimensionPieces.length - 1;
      return;
    }
    this.onResetAction();
  }

  onCalculateVolumeWeight(index: number) {
    this.errorLessThanTotalPieces = false;
    const total =
      (this.formDetailPieces.dimensionPieces[index].length *
        this.formDetailPieces.dimensionPieces[index].width *
        this.formDetailPieces.dimensionPieces[index].height) /
      6000;
    const result = total < 1 ? "1" : Math.round(total).toString();
    this.formDetailPieces.dimensionPieces[index].grossWeight = +result;
    this.onResetAction();
  }

  resetPopupDetailPieces() {
    CargoController.setShowPopupDimensionPieces(false);
    if (!this.isHaveBeenSubmitDetailPieces) {
      CargoController.setFormDetailPieces({
        totalPieces: 0,
        dimensionPieces: [
          {
            pieces: 0,
            length: 30,
            width: 20,
            height: 10,
            grossWeight: 1,
          },
        ],
      });
    }
  }

  onCheckInput() {
    let result = false;
    this.isErrorInputNol.pieces = this.formDetailPieces.dimensionPieces
      .map((key: any) => +key.pieces)
      .includes(0);
    this.isErrorInputNol.dimension =
      this.formDetailPieces.dimensionPieces.map((key: any) => +key.length).includes(0) ||
      this.formDetailPieces.dimensionPieces.map((key: any) => +key.width).includes(0) ||
      this.formDetailPieces.dimensionPieces.map((key: any) => +key.height).includes(0);
    this.isErrorInputNol.volumeWeight = this.formDetailPieces.dimensionPieces
      .map((key: any) => +key.grossWeight)
      .includes(0);
    this.isErrorInputNol.totalPieces = +this.formDetailPieces.totalPieces === 0;
    Object.entries(this.isErrorInputNol).forEach(([, value]) => {
      if (value) {
        result = value;
      }
    });
    this.errorInputNol = result;
  }

  onSubmitPoupDetailPieces() {
    this.onCheckInput();
    if (this.formDetailPieces.totalPieces > this.countPieces) {
      this.errorLessThanTotalPieces = true;
      return;
    }
    if (this.errorMaxThanTotalPieces) {
      return;
    }
    if (this.errorInputNol) {
      return;
    }
    this.onChangeTotalColi(this.formDetailPieces.totalPieces);
    CargoController.setShowPopupDimensionPieces(false);
    MainAppController.showMessageModal(
      new ModalMessageEntities({
        image: "image-modal-success",
        title: "Dimensi Koli Berhasil Disimpan",
        message: "Detail dimensi koli telah berhasil disimpan",
      })
    );
    CargoController.setHaveBeenSubmit(true);
    setTimeout(() => {
      MainAppController.closeMessageModal();
    }, 1000);
  }

  get formActualPieces() {
    return CargoController.form.actualTotalSTTPieces;
  }

  onChangeTotalColi(value: number) {
    CargoController.setOnChangeTotalColi(value);
  }
}
